<template id="item-index">
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <span>{{ $tc('company') }}</span>
      <router-link
        v-if="['1', '2'].includes(setting.roleId)"
        class="card-header-button el-button el-button--primary el-button--mini"
        style="float: right"
        tag="button"
        to="/companies/create"
      >
        <i class="fad fa-plus"></i>&nbsp;{{ $t('create') }}
      </router-link>
    </div>
    <el-tabs type="border-card">
      <el-tab-pane>
        <span slot="label"> <i class="el-icon-search"></i> Por nombre </span>
        <el-form :inline="true">
          <el-form-item>
            <select-company-status v-model="searchStatus"> </select-company-status>
          </el-form-item>
          <el-form-item>
            <el-input v-model="searchName" :placeholder="$t('search')"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              size="mini"
              icon="el-icon-search"
              @click="searchByCompanyName"
            >
              Buscar
            </el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane>
        <span slot="label"> <i class="el-icon-search"></i> Por plan </span>
        <el-form :inline="true">
          <el-form-item>
            <select-service v-model="searchByPlanServiceId"> </select-service>
          </el-form-item>
          <el-form-item>
            <select-service-plan
              v-model="searchByPlanPlanId"
              :init-blank="true"
              :service-id="searchByPlanServiceId"
            ></select-service-plan>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              size="mini"
              icon="el-icon-search"
              @click="searchByServiceByPlan"
            >
              Buscar
            </el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane>
        <span slot="label"> <i class="el-icon-search"></i> Por fecha creacion </span>
        <el-form :inline="true">
          <el-form-item>
            <select-company-status v-model="selectedStatusCreated"> </select-company-status>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              v-model="selectedDateCreated"
              type="daterange"
              :picker-options="pickerOptions"
              range-separator="-"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              unlink-panels
            ></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="mini" icon="el-icon-search" @click="searchByCreated">
              Buscar
            </el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane v-if="['1', '2'].includes(setting.roleId)">
        <span slot="label"> <i class="el-icon-search"></i> Por socio</span>
        <el-form :inline="true">
          <el-form-item>
            <select-partner v-model="partnerSearch"> </select-partner>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="mini" icon="el-icon-search" @click="searchByPartner">
              Buscar
            </el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane>
        <span slot="label"> <i class="el-icon-search"></i> Por vendedor</span>
        <el-form :inline="true">
          <el-form-item>
            <select-user-is-seller v-model="vendorSearch"> </select-user-is-seller>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="mini" icon="el-icon-search" @click="searchByVendor">
              Buscar
            </el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane>
        <span slot="label"> <i class="el-icon-search"></i> Por Servicio</span>
        <el-form :inline="true">
          <el-form-item>
            <select-service v-model="searchService"> </select-service>
          </el-form-item>
          <el-form-item>
            <el-input v-model="searchName" :placeholder="$t('search')"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              size="mini"
              icon="el-icon-search"
              @click="searchByServiceByName"
            >
              Buscar
            </el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane v-if="['1', '2'].includes(setting.roleId)">
        <span slot="label"> <i class="el-icon-check"></i> Cambiar estado</span>
        <el-form :inline="true">
          <el-form-item>
            <select-company-status v-model="companyStatus"> </select-company-status>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="mini" icon="el-icon-check" @click="updateStatus">
              Actualizar
            </el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane v-if="['1', '2'].includes(setting.roleId)">
        <span slot="label"> <i class="el-icon-check"></i> Asignar partner</span>
        <el-form :inline="true">
          <el-form-item>
            <select-partner v-model="selectedPartnerId" @set-partner="setPartner"></select-partner>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="mini" icon="el-icon-check" @click="updatePartner">
              Actualizar
            </el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane v-if="['1', '2'].includes(setting.roleId)">
        <span slot="label"> <i class="el-icon-check"></i> Asignar vendedor</span>
        <el-form :inline="true">
          <el-form-item>
            <select-user-is-seller v-model="vendorSet"> </select-user-is-seller>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="mini" icon="el-icon-check" @click="updateVendor">
              Actualizar
            </el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
    <br />

    <!-- #region  table...  -->
    <el-table
      v-loading="loading"
      size="mini"
      :data="dataSource"
      style="margin-top: 8px; width: 100%"
      stripe
      border
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="expand">
        <template slot-scope="props">
          <p>
            Fecha de creación:
            <strong>{{ props.row.companyServiceCreatedAt | formatToDateTime }}</strong>
          </p>
          <p>
            Ultima de edición:
            <strong>{{ props.row.companyServiceUpdatedAt | formatToDateTime }}</strong>
          </p>
          <p v-if="props.row.companyServiceTrial">
            Fecha de prueba:
            <strong>{{ props.row.companyServiceTrial | formatToDateTime }}</strong>
          </p>
          <p v-if="props.row.companyServiceEnabled">
            Fecha de activación:
            <strong>{{ props.row.companyServiceEnabled | formatToDateTime }}</strong>
          </p>
          <p v-if="props.row.companyServiceReadOnly">
            Fecha de lectura:
            <strong>{{ props.row.companyServiceReadOnly | formatToDateTime }}</strong>
          </p>
          <p v-if="props.row.companyServiceSuspended">
            Fecha de suspención:
            <strong>{{ props.row.companyServiceSuspended | formatToDateTime }}</strong>
          </p>
          <p v-if="props.row.companyServiceCanceled">
            Fecha de cancelación:
            <strong>{{ props.row.companyServiceCanceled | formatToDateTime }}</strong>
          </p>
          <p v-if="props.row.companyServiceDeleted">
            Fecha de eliminación:
            <strong>{{ props.row.companyServiceDeleted | formatToDateTime }}</strong>
          </p>
          <!-- <p>Ultima actualización: <strong>{{ props.row.companyUpdatedAt|formatToDateTime }}</strong></p> -->
        </template>
      </el-table-column>
      <el-table-column type="selection" width="35" align="center"></el-table-column>
      <el-table-column :label="$tc('code')" align="center" width="70">
        <template slot-scope="scope">
          <el-tooltip size="mini" :content="`tenant: ${scope.row.tenantId}`" placement="right">
            <el-tag type="info" size="mini">
              {{ scope.row.companyKey }}
            </el-tag>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        :filter-method="filterStatus"
        :filters="[
          { text: 'Activa', value: 'activa' },
          { text: 'Lectura', value: 'lectura' },
          { text: 'Suspendida', value: 'suspendida' },
          { text: 'Cancelada', value: 'cancelada' },
          { text: 'Prueba', value: 'prueba' },
          { text: 'Demo', value: 'demo' },
          { text: 'Desarrollo', value: 'desarrollo' },
          { text: 'Maestra', value: 'maestra' },
          { text: 'Interna', value: 'interna' },
          { text: 'PruebaFinalizada', value: 'pruebafinalizada' }
        ]"
        prop="companyServiceStatus"
        label="Estado"
        filter-placement="bottom-end"
        width="140"
        align="center"
      >
        <template v-if="scope.row.companyKey != 0" slot-scope="scope">
          <el-button
            size="mini"
            :type="
              scope.row.companyServiceStatus ? scope.row.companyServiceStatus.toLowerCase() : ''
            "
            @click="onViewLogCompanyServiceStatus(scope.row.companyKey, scope.row.serviceId)"
          >
            {{ scope.row.companyServiceStatus ? scope.row.companyServiceStatus.toUpperCase() : '' }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column :label="$tc('person')" align="center" width="80">
        <template slot-scope="scope">
          <el-tooltip
            :content="scope.row.commonPersonId ? $tc('hasPerson') : $tc('noPerson')"
            placement="right"
          >
            <el-button
              size="mini"
              :type="scope.row.commonPersonId ? 'success' : 'danger'"
              circle
              icon="el-icon-user"
              @click="onSetPerson(scope.row)"
            >
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column align="center" width="40">
        <template slot-scope="scope">
          <el-tooltip v-if="scope.row.companyIsPartner" content="Es socio" placement="right">
            <span>
              <i class="fad fa-user-tie"></i>
            </span>
          </el-tooltip>
          <el-tooltip
            v-if="scope.row.idParent"
            :content="`${$tc('hasPartner')}: ${scope.row.parentCompanyName}`"
            placement="right"
          >
            <span>
              <i class="fad fa-people-arrows"></i>
            </span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        :label="$tc('name', 1)"
        :width="180"
        prop="companyName"
        sortable
      ></el-table-column>
      <el-table-column :width="100" prop="companyIdentification" label="ID"></el-table-column>
      <el-table-column
        :filter-method="filterHandlerStatus"
        :filters="services"
        :label="$tc('entity.service', 1)"
        :width="100"
        prop="serviceName"
      ></el-table-column>
      <el-table-column align="center" width="120" :label="$tc('sign')">
        <template slot-scope="scope">
          <el-tooltip
            v-if="
              scope.row.companySignWithApp &&
              scope.row.companyContractSignatureStatus &&
              scope.row.companyContractSignatureStatus.toLowerCase() !== 'rejected'
            "
            :content="
              $t('signWithApp', {
                value:
                  $tc(scope.row.companyContractSignatureStatus, 1) +
                  ' / ' +
                  scope.row.companyContractSignatureStatusBy
              })
            "
            placement="right"
          >
            <div>
              <p>
                <i
                  v-if="
                    scope.row.companyContractSignatureStatus &&
                    scope.row.companyContractSignatureStatus.toLowerCase() === 'approved'
                  "
                  class="fad fa-check"
                ></i>
                <i
                  v-else-if="
                    scope.row.companyContractSignatureStatus &&
                    scope.row.companyContractSignatureStatus.toLowerCase() === 'pending'
                  "
                  class="fad fa-upload"
                ></i>
                <i
                  v-else-if="
                    scope.row.companyContractSignatureStatus &&
                    scope.row.companyContractSignatureStatus.toLowerCase() === 'process'
                  "
                  class="fad fa-clock"
                ></i>
                <span
                  v-if="
                    scope.row &&
                    scope.row.companyContractSignatureStatus &&
                    scope.row.companyContractSignatureStatus.toLowerCase() === 'approved'
                  "
                  >{{ scope.row.companyContractSignatureDateStatus | formatToDate }}</span
                >
              </p>
            </div>
          </el-tooltip>
          <el-tooltip
            v-if="
              scope.row.companySignWithApp &&
              scope.row.companyContractSignatureStatus &&
              scope.row.companyContractSignatureStatus.toLowerCase() === 'rejected'
            "
            :content="
              $t('signWithApp', {
                value:
                  $tc(scope.row.companyContractSignatureStatus, 1) +
                  ' / ' +
                  scope.row.companyContractSignatureStatusBy +
                  ': ' +
                  scope.row.companyContractSignatureReasonStatus
              })
            "
            placement="right"
          >
            <div>
              <p>
                <i class="fad fa-ban"></i>
                <span>{{ scope.row.companyContractSignatureDateStatus | formatToDate }}</span>
              </p>
            </div>
          </el-tooltip>
          <el-tooltip
            v-if="scope.row.companySignWithApp === false"
            :content="$t('signOwner')"
            placement="right"
          >
            <div>
              <i class="fad fa-key"></i>
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column :label="$tc('entity.plan', 1)" :width="185">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click.prevent="onViewPlan(scope.row, 'update')">
            {{ scope.row.planName }}
          </el-button>
          <br />
          <span v-if="scope.row && scope.row.companyServiceAmountTotal">{{
            scope.row.companyServiceAmountTotal | formatToMoney
          }}</span>
          <span v-else="">-o-</span>
          /
          <small v-if="scope.row && scope.row.companyServiceFrequencyBilling">{{
            $tc(toCamelCase(scope.row.companyServiceFrequencyBilling))
          }}</small>
          <span v-else="">-o-</span>
          <br />
          <el-tooltip
            class="item"
            :content="$tc('nextInvoice')"
            effect="dark"
            placement="top-start"
          >
            <i class="fad fa-clock"></i>
          </el-tooltip>
          <span v-if="scope.row && scope.row.companyServiceDateNextInvoice">{{
            scope.row.companyServiceDateNextInvoice | formatToDate
          }}</span>
          <span v-else="">-o-</span>
        </template>
      </el-table-column>
      <el-table-column :label="$tc('log')" width="140">
        <template slot-scope="scope">
          <strong v-if="scope.row.companyServiceStatus === 'prueba'"
            >{{ $tc('dayTrial', 2) }} :</strong
          >
          <br v-if="scope.row.companyServiceStatus === 'prueba'" />
          <el-tag
            v-if="scope.row.companyServiceStatus === 'prueba'"
            :type="scope.row.companyServiceEndTrialExpired ? 'danger' : 'success'"
            effect="plain"
          >
            {{ scope.row.companyServiceEndTrial }}
          </el-tag>
          <br />
          <strong>{{ $tc('created') }} :</strong>
          <br />{{ scope.row.companyServiceCreatedAt | formatToDateTime }}
          <br />
          <strong>{{ $tc('ip') }} : </strong>{{ scope.row.companyServiceCreatedIp }}
        </template>
      </el-table-column>
      <el-table-column label="Último ingreso" width="120">
        <template slot-scope="scope">
          <strong>{{ $tc('date') }} :</strong>
          <br />
          {{ scope.row.companyServiceLastLogin | formatToDate }}
          <br />
          <strong>{{ $tc('quantity') }} :</strong>
          <br />
          <el-tag size="mini" effect="plain">
            {{ scope.row.companyServiceLastLoginDays }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="vendorName" label="Vendedor"></el-table-column>
      <el-table-column :label="$tc('action', 2)" :width="240" align="center">
        <template slot-scope="scope">
          <el-button-group v-if="scope.row.companyKey != 0" size="mini">
            <el-button
              v-if="
                scope.row.companyContractSignatureStatus &&
                scope.row.companyContractSignatureStatus.toLowerCase() === 'process' &&
                (roleIsAdministrator || roleIsSupport)
              "
              type="dark"
              size="mini"
              @click.prevent="showModalContract(scope.row)"
            >
              <i class="fa fa-gavel"></i>
            </el-button>
            <el-button
              v-if="userReadOnly"
              type="primary"
              size="mini"
              @click.prevent="update(scope.row.id)"
            >
              <i class="fa fa-pencil"></i>
            </el-button>
            <!-- <el-tooltip effect="dark" content="Logo" placement="left">
              <el-button size="mini" type="info" @click.prevent="logo(scope.row.id)" ><i class="fa fa-image"></i></el-button>
            </el-tooltip> -->
            <!-- <el-button size="mini" @click.prevent="remove(scope.row.id, scope.$index)" type="danger" ><i class="fa fa-trash"></i></el-button> -->
            <el-tooltip
              v-if="userReadOnly"
              effect="dark"
              content="Agregar servicio"
              placement="left"
            >
              <el-button type="success" size="mini" @click.prevent="onViewPlan(scope.row, 'add')">
                <i class="fa fa-plus"></i>
              </el-button>
            </el-tooltip>
            <el-tooltip
              v-if="userReadOnly"
              effect="dark"
              content="Re-enviar correo de bienvenida"
              placement="left"
            >
              <el-button
                type="info"
                size="mini"
                @click.prevent="resendEmail(scope.row.companyKey, scope.row.serviceId)"
              >
                <i class="fa fa-envelope-square"></i>
              </el-button>
            </el-tooltip>
            <el-tooltip v-if="userReadOnly" effect="dark" content="Paquetes" placement="left">
              <el-button
                type="warning"
                size="mini"
                @click.prevent="onViewServiceFeature(scope.row)"
              >
                <i class="fa fa-box-full"></i>
              </el-button>
            </el-tooltip>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageNumber"
      :page-size="10"
      :page-sizes="[10, 20, 30, 40, 50]"
      :total="rowsCount"
      layout="total, sizes, prev, pager, next, jumper"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    >
    </el-pagination>
    <!-- #endregion  -->

    <!-- #region  plan...  -->
    <el-dialog
      :close-on-click-modal="false"
      :title="$tc('plan')"
      :visible.sync="dialog.plan.visible"
      width="70%"
      @opened="onCustomizePlan"
    >
      <plan v-if="dialog.plan.visible" ref="plan" :mode="dialog.plan.mode"></plan>
    </el-dialog>
    <!-- #endregion -->

    <!-- #region  plan...  -->
    <el-dialog
      :close-on-click-modal="false"
      :title="$tc('feature')"
      :visible.sync="dialog.serviceFeature.visible"
      width="70%"
      @opened="onCustomizeServiceFeature"
    >
      <service-feature v-if="dialog.serviceFeature.visible" ref="serviceFeature"></service-feature>
    </el-dialog>
    <!-- #endregion -->

    <el-dialog title="Log de estados" :visible.sync="dialogLogStatus">
      <el-table v-loading="dialogLogStatusLoading" :data="logStatus" stripe>
        <el-table-column prop="serviceName" label="Servicio"></el-table-column>
        <el-table-column :label="$tc('status', 1)">
          <template slot-scope="scope">
            {{ scope.row.companyServiceStatus.toUpperCase() }}
          </template>
        </el-table-column>
        <el-table-column label="Fecha">
          <template slot-scope="scope">
            {{ scope.row.logStatusAt | formatToDateTime }}
          </template>
        </el-table-column>
        <el-table-column v-if="userIsOwner" label="Por">
          <template slot-scope="scope">
            {{ scope.row.logStatusBy }}
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!-- #region  person  -->
    <el-dialog
      :title="$tc('assingPerson', 1)"
      :visible.sync="dialog.person.visible"
      width="45% "
      append-to-body
    >
      <person
        v-if="dialog.person.visible"
        :model="dialog.person.company"
        @updated="get($event)"
      ></person>
    </el-dialog>
    <!-- #endregion -->
    <el-dialog
      :title="$tc('validateContract', 1)"
      :visible.sync="dialog.contract.visible"
      width="65% "
      append-to-body
    >
      <el-form
        ref="form"
        v-cus-loading="dialog.contract.form.loading"
        :model="dialog.contract.form.model"
        :rules="dialog.contract.form.rules"
      >
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>{{ $tc('document', 2) }}</span>
          </div>
          <table width="100%">
            <col style="width: 65%" />
            <col style="width: 35%" />
            <tfoot>
              <tr v-if="dialog.contract.form.model.companyUrlRut">
                <td>{{ $tc('rut', 1) }}</td>
                <td align="left">
                  <a
                    :href="getAttachmentsUrl(dialog.contract.form.model.companyUrlRut)"
                    target="_blank"
                    rel="noopener noreferrer"
                    >{{ $tc('download') }}</a
                  >
                </td>
              </tr>
              <tr v-if="dialog.contract.form.model.companyUrlChamberCommerce">
                <td>{{ $tc('chamberCommerce', 1) }}</td>
                <td align="left">
                  <a
                    :href="getAttachmentsUrl(dialog.contract.form.model.companyUrlChamberCommerce)"
                    target="_blank"
                    rel="noopener noreferrer"
                    >{{ $tc('download') }}</a
                  >
                </td>
              </tr>
              <tr v-if="dialog.contract.form.model.companyUrlLegalRepresentative">
                <td>{{ $tc('legalRepresentative', 1) }}</td>
                <td align="left">
                  <a
                    :href="
                      getAttachmentsUrl(dialog.contract.form.model.companyUrlLegalRepresentative)
                    "
                    target="_blank"
                    rel="noopener noreferrer"
                    >{{ $tc('download') }}</a
                  >
                </td>
              </tr>
              <tr v-if="dialog.contract.form.model.companyUrlContractMandate">
                <td>{{ $tc('contractMandate', 1) }}</td>
                <td align="left">
                  <a
                    :href="getAttachmentsUrl(dialog.contract.form.model.companyUrlContractMandate)"
                    target="_blank"
                    rel="noopener noreferrer"
                    >{{ $tc('download') }}</a
                  >
                </td>
              </tr>
            </tfoot>
          </table>
        </el-card>

        <el-form-item :label="$tc('status')">
          <select-contract-status
            v-model="dialog.contract.form.model.companyContractSignatureStatus"
            type="customer"
            :clearable="true"
          ></select-contract-status>
        </el-form-item>
        <el-form-item :label="$tc('reason')">
          <el-input
            v-model="dialog.contract.form.model.companyContractSignatureReasonStatus"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            :placeholder="$tc('reason')"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini" @click="onChangeStatusContractSignature()">
            {{ $tc('save') }}
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-card>
</template>
<script>
import { DateTime } from 'luxon'

// import { calculatePage } from '../../assets/js/nbxr'
import { mapFilters } from '@/filters/map-filters'
import { buildQueryString } from '@/helpers'
import dialog from '@/views/common/dialog'
import form from '@/views/common/form'
import { i18n } from '../../i18n'
import router from '../../router'
import modelCompany from './model'

export default {
  name: 'CompanyIndex',
  components: {
    person: (_) => import('./person'),
    plan: (_) => import('./plan'),
    serviceFeature: (_) => import('./service-feature'),
    selectContractStatus: (_) => import('@/components/selects/select-contract-status')
  },
  data() {
    return {
      dialog: {
        person: {
          ...dialog(),
          company: {}
        },
        contract: {
          ...dialog(),
          form: form({
            model: () => ({
              companyContractSignatureStatus: 'approved',
              companyContractSignatureReasonStatus: ''
            }),
            rules: {
              companyContractSignatureStatus: {
                required: true,
                message: this.$tc('required'),
                trigger: ['blur', 'change']
              }
            }
          })
        },
        plan: {
          ...dialog(),
          company: {},
          mode: ''
        },
        serviceFeature: {
          ...dialog(),
          company: {},
          mode: ''
        }
      },
      companies: [],
      dataSource: [],
      company: { ...modelCompany() },
      loading: false,
      pageCount: 0,
      pageSize: 10,
      pageNumber: 1,
      rowsCount: 0,
      serviceId: 1,
      companyStatus: 'Activa',
      searchStatus: '',
      searchName: '',
      searchService: 2,
      searchByPlanServiceId: '',
      searchByPlanPlanId: '',
      filter: '1',
      companiesSelected: [],
      partnerSearch: '',
      selectedPartnerId: '',
      dialogPlan: false,
      dialogPlanLoading: false,

      // dialogAddService: false,
      // dialogAddServiceLoading: false,
      dialogLogStatus: false,
      dialogLogStatusLoading: false,
      vendorSearch: '',
      vendorSet: '',

      // searchByPartnerCompanyId: '',
      logStatus: [],
      plan: '',
      planNew: '',
      serviceMode: '',

      // searchByStatusServiceId: '1',
      // searchByStatusCompanyStatus: '',
      // searchByServiceByStatusServiceId: '1',
      // searchByServiceByStatusCompanyStatus: 'Activa',
      status: [
        { text: '[Sin estado...]', value: null },
        { text: 'Activa', value: 'Activa' },
        { text: 'Lectura', value: 'Lectura' },
        { text: 'Suspendida', value: 'Suspendida' },
        { text: 'Cancelada', value: 'Cancelada' },
        { text: 'Prueba', value: 'Prueba' },
        { text: 'Demo', value: 'Demo' },
        { text: 'Maestra', value: 'Maestra' },
        { text: 'Interna', value: 'Interna' }
      ],
      services: [
        { text: '[Sin servicio...]', value: null },
        { text: 'Samba FE', value: 'Samba FE' },
        { text: 'Samba ERP/HRM', value: 'Samba ERP/HRM' },
        { text: 'Samba ERP', value: 'Samba ERP' },
        { text: 'Samba HRM', value: 'Samba HRM' },
        { text: 'Smartgrade', value: 'Smartgrade' },
        { text: 'Perito', value: 'Perito' },
        { text: 'Gasnet', value: 'Gasnet' },
        { text: 'Cumbre', value: 'Cumbre' },
        { text: 'Samba CRM', value: 'Samba CRM' }
      ],
      selectedStatusCreated: '',
      selectedDateCreated: [DateTime.local().toISODate(), DateTime.local().toISODate()],
      pickerOptions: {
        shortcuts: [
          {
            text: 'Hoy',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: 'Ultima semana',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: 'Ultimo mes',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: 'Ultimos 3 meses',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: 'Ultimos 12 meses',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      rulesCompany: {
        serviceId: [{ required: true, message: i18n.t('required'), trigger: 'change' }],
        planId: [{ required: true, message: i18n.t('required'), trigger: 'change' }],
        companyServiceFrequencyBilling: [
          { required: true, message: i18n.t('required'), trigger: 'change' }
        ],
        userFirstName: [{ required: true, message: i18n.t('required'), trigger: 'blur' }],
        userLastName: [{ required: true, message: i18n.t('required'), trigger: 'blur' }],
        userName: [{ required: true, message: i18n.t('required'), trigger: 'blur' }],
        userEmail: [
          { required: true, message: i18n.t('required'), trigger: 'blur' },
          {
            type: 'email',
            message: i18n.t('invalidEmail'),
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },
  computed: {
    roleIsAdministrator: (_this) => [1, 2].includes(+_this.roleId),
    roleIsSeller: (_this) => [4].includes(+_this.roleId),
    roleIsSupport: (_this) => [3].includes(+_this.roleId),
    userIsOwner: () => {
      const data = JSON.parse(localStorage.getItem('masters'))

      return (
        data &&
        (data.username.includes('fpinzon') ||
          data.username.includes('waguirre') ||
          data.username.includes('jhernandeza') ||
          data.username.includes('cangulo'))
      )
    },
    roleId() {
      return JSON.parse(localStorage.getItem('masters')).roleId
    },
    userReadOnly() {
      return this.roleId.localeCompare('1') === 0 || this.roleId.localeCompare('2') === 0
    },
    total() {
      const _this = this
      let totalServicePrice =
        _this.company.companyServiceFrequencyBilling.toLowerCase() === 'yearly'
          ? _this.plan.planPriceYearly
          : _this.plan.planPriceMonthly

      if (_this.company.features) {
        for (let i = 0; i < _this.company.features.length; i++) {
          if (
            _this.company.features[i].featureAddonQuantity &&
            _this.company.features[i].featureAddonQuantity.toString() !== 'false'
          ) {
            let value = parseFloat(_this.company.features[i].featureUnitPriceCustom)
            if (!isNaN(_this.company.features[i].featureAddonQuantity.toString())) {
              value =
                parseFloat(_this.company.features[i].featureUnitPriceCustom) *
                parseFloat(_this.company.features[i].featureAddonQuantity)
            }
            if (_this.company.companyServiceFrequencyBilling.toLowerCase() === 'yearly') {
              value *= 12
            }
            totalServicePrice += value
          }
        }
      }
      if (_this.serviceMode === 'add') {
        _this.company.companyServicePrice = totalServicePrice
      }

      return totalServicePrice
    }
  },
  methods: {
    ...mapFilters(['toCamelCase']),
    onSetPerson(company) {
      const _this = this
      _this.dialog.person.visible = true
      _this.dialog.person.company = {
        ...company
      }
    },
    showModalContract(company) {
      const _this = this
      _this.dialog.contract.visible = true
      _this.dialog.contract.form.model = {
        ...company
      }
    },
    onChangeStatusContractSignature() {
      const _this = this
      _this.dialog.contract.form.loading = true
      _this.$http
        .put(
          `companies/${_this.dialog.contract.form.model.id}/contract`,
          _this.dialog.contract.form.model
        )
        .then(
          (response) => {
            _this.dialog.contract.form.loading = false
            _this.dialog.contract.visible = false
            _this.$message({
              message: i18n.t('processSuccess'),
              type: 'success'
            })
            _this.get()

            return response
          },
          (_) => {
            _this.dialog.contract.visible = false
          }
        )
        .finally((_) => {
          _this.dialog.contract.visible = false
          _this.dialog.contract.form.loading = false
        })
    },
    getAttachmentsUrl(url) {
      return `${process.env.VUE_APP_URI_ATTACHMENTS}${encodeURIComponent(url)}`
    },
    getBySearchByStatus() {
      const _this = this
      _this.loading = true
      _this.$http
        .get(
          `companies/findBySearchByStatus?search=${_this.searchName}&status=${
            _this.searchStatus
          }&limit=${_this.pageSize}&offset=${_this.pageNumber - 1}`
        )
        .then(
          (response) => {
            _this.loading = false
            _this.companies = response.body.data
            _this.dataSource = response.body.data
            _this.rowsCount = response.body.count
            _this.find()
          },
          (response) => {
            _this.loading = false
          }
        )
    },
    getByServiceByPlan() {
      const _this = this
      _this.loading = true
      _this.$http
        .get(
          `companies/findByServiceByPlan?serviceId=${_this.searchByPlanServiceId}&planId=${
            _this.searchByPlanPlanId ? _this.searchByPlanPlanId.id : ''
          }&limit=${_this.pageSize}&offset=${_this.pageNumber - 1}`
        )
        .then(
          (response) => {
            _this.loading = false
            _this.companies = response.body.data
            _this.dataSource = response.body.data
            _this.rowsCount = response.body.count
            _this.find()
          },
          (response) => {
            _this.loading = false
          }
        )
    },
    getByCreatedBystatus() {
      const _this = this
      _this.loading = true
      _this.$http
        .get(
          `companies/findByCreatedByStatus?dateFrom=${DateTime.fromISO(
            _this.selectedDateCreated[0]
          ).toISODate()}&dateTo=${DateTime.fromISO(
            _this.selectedDateCreated[1]
          ).toISODate()}&status=${_this.selectedStatusCreated}&limit=${_this.pageSize}&offset=${
            _this.pageNumber - 1
          }`
        )
        .then(
          (response) => {
            _this.loading = false
            _this.companies = response.body.data
            _this.dataSource = response.body.data
            _this.rowsCount = response.body.count
            _this.find()
          },
          (response) => {
            _this.loading = false
          }
        )
    },
    getByPartner() {
      const _this = this
      _this.loading = true
      _this.$http
        .get(
          `companies/findByPartner?id=${_this.partnerSearch}&limit=${_this.pageSize}&offset=${
            _this.pageNumber - 1
          }`
        )
        .then(
          (response) => {
            _this.loading = false
            _this.companies = response.body.data
            _this.dataSource = response.body.data
            _this.rowsCount = response.body.count
            _this.find()
          },
          (response) => {
            _this.loading = false
          }
        )
    },
    getByVendor() {
      const _this = this
      _this.loading = true
      _this.$http
        .get(
          `companies/findByVendor?id=${_this.vendorSearch}&limit=${_this.pageSize}&offset=${
            _this.pageNumber - 1
          }`
        )
        .then(
          (response) => {
            _this.loading = false
            _this.companies = response.body.data
            _this.dataSource = response.body.data
            _this.rowsCount = response.body.count
            _this.find()
          },
          (response) => {
            _this.loading = false
          }
        )
    },
    getByServiceByName() {
      const _this = this
      _this.loading = true
      let queryParams = {
        limit: _this.pageSize,
        offset: 0
      }
      if (_this.searchName.length > 0) {
        queryParams = {
          ...queryParams,
          queryPhrase: _this.searchName
        }
      }
      if (+_this.searchService > 0) {
        queryParams = {
          ...queryParams,
          serviceId: _this.searchService
        }
      }
      _this.$http.get(`companies/search?${buildQueryString(queryParams)}`).then(
        (response) => {
          _this.loading = false
          _this.companies = response.body.data
          _this.dataSource = response.body.data
          _this.rowsCount = response.body.count
          _this.find()
        },
        (response) => {
          _this.loading = false
        }
      )
    },
    get() {
      if (this.filter === '1') {
        this.getBySearchByStatus()
      } else if (this.filter === '2') {
        this.getByServiceByPlan()
      } else if (this.filter === '3') {
        this.getByPartner()
      } else if (this.filter === '4') {
        this.getByVendor()
      } else if (this.filter === '5') {
        this.getByCreatedBystatus()
      } else if (this.filter === '6') {
        this.getByServiceByName()
      }
    },
    find() {
      const _this = this
      _this.loadingForm = true
      _this.pageCount = Math.ceil(_this.rowsCount / _this.pageSize)
      if (_this.pageCount > 0 && _this.pageCount < _this.pageNumber) {
        _this.pageNumber = 1

        if (_this.$refs.paginate) {
          _this.$refs.paginate.selected = 0
        }
      }

      _this.loadingForm = false
    },
    filterHandlerStatus(value, row, column) {
      const { property } = column

      return row[property] === value
    },
    setPartner(value) {
      const _this = this
      _this.loading = true
      if (value) {
        _this.partnerValue = value
      } else {
        _this.partnerValue = {}
      }
      _this.loading = false
    },
    remove(id, index) {},
    handleSizeChange(val) {
      this.pageSize = val
      this.get()
    },
    handleCurrentChange(val) {
      this.pageNumber = val
      this.get()
    },
    update(id) {
      router.push({ name: 'CompanyUpdate', params: { id } })
    },
    logo(id) {
      router.push({ name: 'CompanyUpdateLogo', params: { id } })
    },
    searchByCompanyName() {
      this.filter = '1'
      this.get()
    },
    searchByServiceByPlan() {
      this.filter = '2'
      this.get()
    },
    searchByPartner() {
      this.filter = '3'
      this.get()
    },
    searchByVendor() {
      this.filter = '4'
      this.get()
    },
    searchByCreated() {
      this.filter = '5'
      this.get()
    },
    searchByServiceByName() {
      this.filter = '6'
      this.get()
    },
    onCustomizePlan() {
      const _this = this
      setTimeout(() => _this.$refs.plan.fillForm(_this.dialog.plan.company), 10)
    },
    onViewPlan(value, mode) {
      const _this = this
      _this.dialog.plan.mode = mode
      _this.dialog.plan.company = {
        ...value,
        ...{
          plan: {
            features: []
          }
        }
      }
      _this.dialog.plan.visible = true
    },
    onCustomizeServiceFeature() {
      const _this = this
      setTimeout(() => _this.$refs.serviceFeature.fillForm(_this.dialog.serviceFeature.company), 10)
    },
    onViewServiceFeature(value) {
      const _this = this
      _this.dialog.serviceFeature.company = {
        ...value,
        ...{
          companyServiceFeatures: []
        }
      }
      _this.dialog.serviceFeature.visible = true
    },
    handleSelectionChange(val) {
      this.companiesSelected = val
    },
    updateStatus() {
      const _this = this
      const updateTask = new Promise((resolve, reject) => {
        let i = 0
        _this.companiesSelected.forEach((row, index, array) => {
          _this.companiesSelected[index].companyServiceStatus = _this.companyStatus
          i++
        })
        if (_this.companiesSelected.length === i) {
          resolve()
        }
      })
      updateTask.then(() => {
        _this.loading = true
        _this.$http
          .patch('companies/services/status', JSON.stringify(_this.companiesSelected))
          .then(
            (response) => {
              _this.loading = false
              _this.get()
              _this.$message({
                message: i18n.t('processSuccess'),
                type: 'success'
              })
            },
            (response) => {
              _this.loading = false
            }
          )
      })
    },
    updatePartner() {
      const _this = this
      if (_this.partnerValue && _this.partnerValue.id) {
        const updateTask = new Promise((resolve, reject) => {
          let i = 0
          _this.companiesSelected.forEach((company, index, array) => {
            _this.companiesSelected[index].idParent = _this.partnerValue.id
            _this.companiesSelected[index].companyKeyParent = _this.partnerValue.companyKey
            i++
          })
          if (_this.companiesSelected.length === i) {
            resolve()
          }
        })
        updateTask.then(() => {
          _this.loading = true
          _this.$http.patch('companies/partners', JSON.stringify(_this.companiesSelected)).then(
            (response) => {
              _this.loading = false
              _this.get()
              _this.$message({
                message: i18n.t('processSuccess'),
                type: 'success'
              })
            },
            (response) => {
              _this.loading = false
            }
          )
        })
      } else {
        _this.$message({ message: 'Seleccione el socio!', type: 'error' })
      }
    },
    updateVendor() {
      const _this = this
      const companiesSelected = _this.companiesSelected.filter((i) => i.serviceId > 0)
      if (_this.vendorSet) {
        if (companiesSelected.length > 0) {
          const updateTask = new Promise((resolve, reject) => {
            let i = 0
            companiesSelected.forEach((row, index, array) => {
              companiesSelected[index].vendorUserId = _this.vendorSet
              i++
            })
            if (companiesSelected.length === i) {
              resolve()
            }
          })
          updateTask.then(() => {
            _this.loading = true
            _this.$http.patch('companies/vendor', JSON.stringify(companiesSelected)).then(
              (response) => {
                _this.loading = false
                _this.get()
                _this.$message({
                  message: i18n.t('processSuccess'),
                  type: 'success'
                })
              },
              (response) => {
                _this.loading = false
              }
            )
          })
        } else {
          _this.$message({
            message: 'Solo se puede asignar un vendedor cuando la empresa tiene un plan!',
            type: 'error'
          })
        }
      } else {
        _this.$message({ message: 'Seleccione el vendedor!', type: 'error' })
      }
    },
    resendEmail(companyKey, serviceId) {
      const _this = this
      _this.$http.get(`companies/resendEmail/${companyKey}/${serviceId}`).then(
        (response) => {
          _this.loading = false
          _this.get()
          _this.$message({
            message: i18n.t('processSuccess'),
            type: 'success'
          })
        },
        (response) => {
          _this.loading = false
        }
      )
    },
    onViewLogCompanyServiceStatus(companyKey, serviceId) {
      const _this = this
      _this.dialogLogStatus = true
      _this.dialogLogStatusLoading = true
      _this.$http
        .get(`logStatus/findByCompanyByService?companyKey=${companyKey}&serviceId=${serviceId}`)
        .then(
          (response) => {
            _this.logStatus = response.body
            _this.dialogLogStatusLoading = false
          },
          (response) => {
            _this.dialogLogStatusLoading = false
          }
        )
    },
    filterStatus(value, row) {
      return row.companyServiceStatus === value
    }
  },
  mounted() {}
}
</script>
